<template>
    <button class="col d-flex flex-column justify-content-center align-items-center cuenta_a btn_none" v-if="userData">
        <img :src="userData.avatar" alt="avatar" class="col-2 d-flex foto-perfil">
        <!-- <img src="../../img/nav-icons/perfil-foto.svg" alt="" class="col-2 d-flex foto-perfil" v-else> -->
        <ul class="justify-content-center align-items-center cuenta__a_ul">
            <Idioma :clase="true"/>
            <li @click="AccountNav()" class="borde  d-flex justify-content-start align-items-center">
                <img src="../../img/nav-icons/usuario.svg" alt="">{{ $t('message.modalAccount') }}</li>
            <li class="borde  d-flex justify-content-start align-items-center" @click="logout">
                <img src="../../img/nav-icons/log-out.svg" alt="">{{ $t('message.modalExit') }}</li>
        </ul>
    </button>
</template>

<script setup>
import { ref } from "vue";
import { useUsersStore } from "../../stores/user"
import { storeToRefs } from "pinia";
import Idioma from "./Utilidades/Idioma.vue";
import { useRouter } from 'vue-router';

const router = useRouter()

const user = useUsersStore();
const {datosUsuario, headers, idUser, userData} = storeToRefs(user);
const {logout,  usuarioData} = user;

const carrito = ref(true)
//console.log(datosUsuario.value.image)

const AccountNav = () => {
    console.log(idUser.value)
    if(idUser.value.team == 1){
        router.push({name: 'AjustesAdmin'})
    } else if (idUser.value.team >= 3 && idUser.value.team <= 5){
        router.push({name: 'Cuenta'})
    }
}
</script>

<style lang="scss" scoped>
.navbar{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    height: 68px;
    a{
        img{
            max-height: 50px;
            @media (min-width: 992px) {
                max-height: 50px;
            }
        }
    }
    .container-fluid{
        height: 100%;
    }
    .navbar-toggler{
        border: 0;
        padding: 0;
        outline: 0;
        .navbar-toggler-icon{
            background-image: url('../../img/menu-icons/menu-icon.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            height: 25px;
            width: 29px;
        }
        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
    .nav-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--enlacesNav);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        margin: 10px 0px;
        position: relative;
        img{
            margin-right: 20px;
        }
        .position{
            position: absolute;
            right: 0;
        }
        i{
            margin-right: 15px;
            min-height: 23px;
            min-width: 23px;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }
        .cursos{
            background-image: url('../../img/menu-icons/cursos.svg');
        }
        .categorias{
            background-image: url('../../img/menu-icons/categorias.svg');
        }
        .escuela{
            background-image: url('../../img/menu-icons/escuela.svg');
        }
        .concursos{
            background-image: url('../../img/menu-icons/concursos.svg');
        }
        .nosotros{
            background-image: url('../../img/menu-icons/nosotros.svg');
        }
        .contacto{
            background-image: url('../../img/menu-icons/contacto.svg');
        }
        .premium{
            background-image: url('../../img/menu-icons/premium.svg');
        }
        &:focus, &:hover{
            color: var(--colorPrimary);
            font-weight: 700;
            &::before{
                content: '';
                background-color: var(--colorPrimary);
                height: 100%;
                position: absolute;
                width: 5px;
                border-radius: 10px;
                left: -8.5%;
                top: 0;
            }
            .cursos{
                background-image: url('../../img/menu-icons/cursos_active.svg');
            }
            .categorias{
                background-image: url('../../img/menu-icons/categorias_active.svg');
            }
            .escuela{
                background-image: url('../../img/menu-icons/escuela_active.svg');
            }
            .concursos{
                background-image: url('../../img/menu-icons/concursos_actives.svg');
            }
            .nosotros{
                background-image: url('../../img/menu-icons/nosotros_active.svg');
            }
            .contacto{
                background-image: url('../../img/menu-icons/contacto_active.svg');
            }
            .premium{
                background-image: url('../../img/menu-icons/premium-active.svg');
            }
        }
    }
    .navbar-brand{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        p{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorPrimary);
        }
    }
    .dropdown-toggle::after{
        border: 0;
    }
    ul{
        h4{
            color: var(--grisPrimario);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 0 0 10px 0;
        }
        li{
            padding: 0;
        }
    }
    .btns{
        margin: 10px 0px;
    }
    .ul_precios{
        padding-left: 0;
        list-style: none;
        padding-bottom: 40px;
        max-width: 75px;
        ul{
            width: 250px;
            padding: 20px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 20px;
            background-color: #fff;
            border: 0;
            li{
                padding-bottom: 10px;
                a{
                    color: var(--colorSecondary);
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                }
            }
        }
        p, img.padding{
            margin-right: 5px;
        }
        .nav-link{
            color: var(--colorSecondary);
            &:focus, &:hover{
                color: var(--colorPrimary);
                font-weight: 700; 
                &::before{
                    display: none !important;
                }
            }
        }
    }
    .boder-top{
        border-top: 1px solid rgba(184, 193, 219, 0.5);
        h6{
            color: var(--grisSecundario);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
        }
        .form-check-input{
            border-color: var(--colorPrimary);
            position: relative;
            display: flex;
            align-items: center;
        }
        .form-switch .form-check-input {
            height: 25px;
            width: 48px;
            background-image: url('../../img/menu-icons/switch_normal.svg');
            background-size: 42% 100%;
            background-position-x: 2px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                right: 5px;
            }
            &::after{
                display: none;
            }
        }
        .form-switch .form-check-input:checked{
            background-image: url('../../img/menu-icons/switch_active.svg');
            background-position-x: 25px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                filter: brightness(10);
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                left: 5px;
            }
        }
        .form-switch .form-check-input:focus{
            box-shadow: none;
        }

    }
    .navbar-nav .dropdown-menu{
        position: absolute;
    }
    .row__lg__nav{
        .btn_none{
            border: 0;
            background-color: transparent;
            &:focus{
                outline: none;
            }
        }
        .cuenta_a{
            background-color: transparent;
            border: 0;
            outline: 0;
            max-width: 60px;
            height: 39px !important;
            width: 39px !important;
            position: relative;
            .foto-perfil{
                height: 100%;
                width: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
            .cuenta__a_ul{
                position: absolute;
                border-radius: 20px;
                top: 100%;
                background-color: var(--bgPrincipal);
                box-shadow: 0px 3px 6px #0000002a;
                right: 10%;
                padding: 20px;
                width: 250px;
                display: none;
                li{
                    width: 100%;
                    padding: 10px 0px 10px 0px;
                    img{
                        margin-left: 10px;
                    }
                }
                
                .borde{
                    border-top: 1px solid #c6bacc7c;
                    padding: 15px 0px;
                    img{
                        margin-right: 10px;
                        margin-left: 0;
                    }
                }
            }
            &:focus{
                color: inherit;
                &::before{
                    display: none;
                }
                ul{
                    display: block;
                }
            }
        }
    }
    .offcanvas-carrito{
        min-width: 436px;
        padding: 20px;
        .offcanvas-title{
            font-size: 20px;
            font-weight: 700;
            color: var(--colorSecondary);
        }
        .div{
            min-height: 80%;
            padding: 20px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #FCFCFC;
            h2{
                font-size: 20px;
                font-weight: 700;
                color: var(--colorSecondary);
            }
            img{
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                margin-bottom: 20px;
            }
            h3{
                font-size: 16px;
                font-weight: 400;
                color: #697891;
                text-align: center;
                padding: 10px 0px;
            }
        }
        .offcanvas .btn-close{
            background-image: url('../../img/carrito-icons/cerrar-modal.svg');
            opacity: 1;
        }
        .borde{
            border: 1px solid rgba(184, 193, 219, 0.5);
        }
        .modal-carrito{
            .offcanvas-header{
                margin-bottom: 10px !important;
            }
            h4{
                font-weight: 700;
                font-size: 22px;
                color: var(--colorSecondary);
            }
            p{
                font-weight: 500;
                font-size: 16px;
                color: #697891;
                padding: 10px 0px;
            }
            .btn{
                max-width: 90%;
            }
        }
    }
    .offcanvas-backdrop{
        backdrop-filter: blur(3px);
        @media (min-width: 992px) {
            top: 95px !important;
            backdrop-filter: blur(10px);
            background-color: var(--colorSecondary);
        }
    }
    @media (min-width: 992px) {
        height: 95px;
    }    
}
.offcanvas{
        padding: 10px 10px;
        max-width: 85%;
        border-radius: 0px 0px 15px 0px;
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 100vh;
        flex-direction: row;
        .offcanvas-header{
            align-items: flex-start;
            padding: 10px 25px;
        }
        .btn-close{
            background-image: url('../../img/menu-icons/cerrar.svg');
        }
        .offcanvas-body{
            padding: 10px 25px;
        }
        @media (min-width: 992px) {
            background-color: var(--bgPrincipal) !important;
            box-shadow: 0px 3px 6px #00000029;
            top: 95px;
        }
    }
.offcanvas-backdrop.show{
    backdrop-filter: blur(3px);
}
.navbar-nav .dropdown-menu{
    position: absolute;
}

.div{
    li{
        width: 100%;
        padding: 10px 0px 10px 0px;
        cursor: pointer;
    }
    a{
        font-weight: inherit;
    }
}
.borde{
    text-decoration: none;
}

</style>