<template>
    <div class="row justify-content-center footer">
        <router-link to="/" class="col d-flex flex-column justify-content-center align-items-center" :class="activeHome ? 'active' : ''"> <i class="home"></i> Home</router-link>
        <router-link to="/mis-productos" class="col d-flex flex-column justify-content-center align-items-center" :class="activeCursos ? 'active' : ''" > <i class="cursos"></i> Cursos</router-link>
        <router-link to="/carrito/2" class="col d-flex flex-column justify-content-center align-items-center" :class="activeCarrito ? 'active' : ''"> <i class="carrito"></i> Carrito <span>1</span></router-link>
        <button class="col d-flex flex-column justify-content-center align-items-center cuenta_a" :class="account ? 'active' : ''" v-if="userData">
            <img :src="userData.avatar" alt=""  class="col-2 d-flex foto-perfil" v-if="userData.avatar">  
            <img src="../../img/nav-icons/perfil-foto.svg" alt="" class="col-2 d-flex foto-perfil" v-else> 
            Cuenta 
             <div class="div">
                <img src="../../img/nav-icons/mas.svg" alt="">
            </div>
            <MenuModal/>
        </button>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useUsersStore } from "../../stores/user" 
import { storeToRefs } from "pinia";

import MenuModal from '../Navs/Utilidades/MenuModal.vue';

const router = useRouter()
const user = useUsersStore();
const {datosUsuario, headers, idUser, userInformation, userData} = storeToRefs(user);
const {} = user;

const props = defineProps({
    activeMenu: Boolean,
    activeHome: Boolean,
    activeCursos: Boolean,
    activeCarrito: Boolean,
    activeCuenta: Boolean,
})

console.log(userData.value)
</script>

<style lang="scss" scoped>
.footer{
    background-color: var(--bgPrincipal);
    box-shadow: 0px 2px 15px #00000026;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 85px;
    z-index: 99;
    a, button{
        font-weight: 500;
        font-size: 14px;
        color: var(--colorSecondary);
        text-decoration: none;
        position: relative;
        border: 0;
        background-color: transparent;
        &:focus, &:hover{
            color: var(--colorPrimary);
            font-weight: 600;
            &:before{
                content: '';
                position: absolute;
                top: 0;
                background-color: var(--colorPrimary);
                height: 4px;
                width: 100%;
                border-radius: 0px 0px 10px 10px;
            }
            .menu{
                background-image: url('../../img/nav-icons/menu-active.svg');
            }
            .home{
                background-image: url('../../img/nav-icons/home-active.svg');
            }
            .cursos{
                background-image: url('../../img/nav-icons/cursos-active.svg');
            }
            .carrito{
                background-image: url('../../img/nav-icons/carrito-active.svg');
            }
        }
        span{
            background-color: var(--colorPrimary);
            height: 15px;
            width: 15px;
            border-radius: 50%;
            position: absolute;
            right: 25%;
            top: 15%;
            color: #FFFFFF;
            font-weight: 600;
            font-size: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .div{
            background-color: var(--colorSecondary);
            height: 15px;
            width: 15px;
            border-radius: 50%;
            position: absolute;
            right: 25%;
            top: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                max-height: 7px;
            }
        }
        ul{
            position: absolute;
            border-radius: 20px;
            top: -290px;
            background-color: var(--bgPrincipal);
            box-shadow: 0px 3px 6px #0000002a;
            right: 10%;
            padding: 20px;
            width: 250px;
            display: none;
            li{
                width: 100%;
                padding: 10px 0px 10px 0px;
                img{
                    margin-left: 10px;
                }
            }
            .borde{
                border-top: 1px solid #c6bacc7c;
                padding: 15px 0px;
                img{
                    margin-right: 10px;
                    margin-left: 0;
                }
            }
        }
        
    }
    .cuenta_a{
        &:hover,&:focus{
            color: inherit;
            &::before{
                display: none;
            }
            ul{
                display: block;
            }
        }
    }
    .active{
        color: var(--colorPrimary);
        font-weight: 600;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            background-color: var(--colorPrimary);
            height: 4px;
            width: 100%;
            border-radius: 0px 0px 10px 10px;
        }
        .menu{
            background-image: url('../../img/nav-icons/menu-active.svg');
        }
        .home{
            background-image: url('../../img/nav-icons/home-active.svg');
        }
        .cursos{
            background-image: url('../../img/nav-icons/cursos-active.svg');
        }
        .carrito{
            background-image: url('../../img/nav-icons/carrito-active.svg');
        }
    }
    i{
        display: block;
        min-height: 25px;
        min-width: 25px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 5px;
    }
    .menu{
        background-image: url('../../img/nav-icons/menu.svg');
    }
    .home{
        background-image: url('../../img/nav-icons/home.svg');
    }
    .cursos{
        background-image: url('../../img/nav-icons/cursos.svg');
    }
    .carrito{
        background-image: url('../../img/nav-icons/carrito.svg');
    }
    .cuenta{
        background-image: url('../../img/nav-icons/cuenta.svg');
    }
    .foto-perfil{
        width: 25px;
        height: 25px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 5px
    }
}
</style>