<template>
    <HeaderTitulo class="d-none d-lg-flex" :tituloHeader="$t('message.HomePresets')"/>
    <div class="row justify-content-center">
        <div class="col-12 col-lg-11 d-flex justify-content-center align-items-center px-0">
            <div class="row justify-content-center justify-content-lg-between row__width">
                <div class="col-12 col-lg-7 d-flex flex-column justify-content-start align-items-center px-0  position-relative">
                    <ProgresoCarrito :uno='true' :dos="true" class="mx-lg-2"/>
                    <div class="row justify-content-center row__width">
                        <div class="col-11 col-lg-12 d-flex flex-column justify-content-center align-items-center px-0 mt-2">
                            <TituloCarrito :oculto="false" :titulo="$t('message.dataReview')" :cliente="$t('message.existingCustomer')" :ingrese="$t('message.loginAccount')"/>
                            <InputsCheckout/>
                            <FooterCarrito class="d-none d-lg-flex"/>
                            <ResumenCestaCheckout class="d-lg-none"/>
                            <DetallesCarritoV3 class=" d-lg-none"/>
                            <BTNPrimario :textoBTNPrimario="$t('message.finishBuy')" class="mt-5 d-lg-none" @click="checkoutButton()"/>
                            <CondicionesCheckout class="d-lg-none"/>
                            <FooterCarrito class="d-lg-none"/>
                            <DatosRevisarFacebook class="d-none"/>
                            <DatosRevisarGoogle class="d-none"/>
                            <DatosRevisarInputs class="d-none"/>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-xxl max-width d-none d-lg-flex justify-content-center align-items-start pt-5 mt-5 px-0">
                    <div class="box-shadow row__width">
                        <ResumenCestaCheckoutV2 class="d-none d-lg-flex"/>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 d-none">
                            <ProductosAdicionales/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import BTNPrimario from "../../components/Botones/PrimarioCheckout.vue"
import DetallesCarritoV3 from "../../components/Carrito/DetallesV3.vue"
import ProgresoCarrito from "../../components/Carrito/Progreso.vue"
import FooterCarrito from "../../components/Footer/FooterCarrito.vue"
import TituloCarrito from "../../components/Titulo/TituloCarrito.vue"
import ResumenCestaCheckout from "../../components/Carrito/ResumenCestaCheckout.vue"
import InputsCheckout from "../../components/Inputs/Checkout.vue"
import CondicionesCheckout from "../../components/Condiciones/CondicionesCheckout.vue"
import HeaderTitulo from "../../components/Titulo/HeaderTitulo.vue"
import ResumenCestaCheckoutV2 from "../../components/Carrito/ResumenCestaCheckoutV2.vue"
import DatosRevisarGoogle from "../../components/Datos/DatosRevisarGoogle.vue"
import DatosRevisarInputs from "../../components/Inputs/DatosRevisar.vue"
import DatosRevisarFacebook from "../../components/Datos/DatosRevisarFacebook.vue"
import ProductosAdicionales from "../../components/Sliders/ProductosAdicionales.vue"
import { useRoute, useRouter } from 'vue-router';
import { ref, inject, provide } from 'vue';

const route = useRoute()
const router = useRouter()

let step = inject('step')

let cart = inject('cart')

const checkoutButton = () => {
    if(step.value == 3){
        router.replace('/carrito/4')
        step.value = 4
    }
}
</script>


<style lang="scss" scoped>

p{
    font-weight: 500;
    font-size: 16px;
    color: #697891;
    padding: 10px 0px;
    margin-bottom: 30px;
}
@media (min-width: 1024px){
    .max-width{
        max-width: 33%;
    }
}
@media (min-width: 1440px){
    .max-width{
        max-width: 34%;
    }
}
</style>