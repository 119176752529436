<template>
    <div class="row justify-content-center justify-content-lg-start mb-3 row__width" :style="filtrosMapa == true ? 'z-index: -1;' : 'z-index: 1;'">
        <swiper-container
            :navigation="{
                nextEl: '#nextSliderCursosboton',
                prevEl: '#prevSliderCursosboton'
            }"
            :pagination="{
                el: '#pagCursos',
                type: 'fraction',
                currentClass: 'paginationCurrentStyle',
                totalClass: 'paginationTotalStyle'
            }"
            :autoplay="true"
            :slides-per-view="1"
            :slides-per-group="1"
            :spaceBetween="20"
            :breakpoints="{
                375: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                992: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                1875: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
            }"
            @swiper="onSwiper"
            class="mySwiper">
            <swiper-slide v-for="(product, id) in products_list" class="d-flex justify-content-center align-items-center">
                <CursosFotografiaSlideV2    :rName="product.name"
                                            :rSlug="product.slug"
                                            :rOwner="product.professional.name"
                                            :rOwnerAvatar="product.professional.avatar"
                                            :rImage="product.presentations[0].url"
                                            :rModality="product.modality.translation.name"
                                            :rDetail="product.details"
                                            :rPrice="product.prices[0]"
                                            :rVerified="product.professional.verified"
                                            :rProductVerified="false"
                                            :rCreatedAt="0"
                                            :rCollabs="product.collaborators"
                                            :rReviews="product.reviews"
                                            :rMarketplace="product.marketplace"
                                            :rMembership="product.membership"
                                            :rPurchases="product.purchases_count" :key="product"/>
            </swiper-slide>
            <swiper-slide v-for="(product, id) in 4" v-if="products_list.length <= 0">
                <div class="skeleton-card slide">
                    <div class="skeleton-header">
                        <div class="skeleton-image"></div>
                    </div>
                    <div class="skeleton-body">
                        <div class="skeleton-title skeleton-loading"></div>
                        <div class="skeleton-subtitle skeleton-loading"></div>
                        <div class="skeleton-meta">
                            <div class="skeleton-icon skeleton-loading"></div>
                        </div>
                        <div class="skeleton-footer" style="margin-bottom: 16px;">
                            <div class="skeleton-price skeleton-loading"></div>
                            <div class="skeleton-price skeleton-loading"></div>
                            <div class="skeleton-price skeleton-loading"></div>
                            <div class="skeleton-price skeleton-loading"></div>
                        </div>
                        <div class="skeleton-footer">
                            <div class="skeleton-price skeleton-loading"></div>
                            <div class="skeleton-button skeleton-loading"></div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
    <Colaboradores/>
</template>

<script setup>
import Colaboradores from "../Modales/Colaboradores.vue";

import CursosFotografiaSlideV2 from "./CursosFotografiaV2.vue";
import { useGeneralStore } from "../../stores/general"
import { useConfigStore } from "../../stores/config"
import { useUsersStore } from "../../stores/user" 
import { storeToRefs } from "pinia";
import { ref, watch, nextTick, inject, onMounted } from 'vue';
import { useI18n } from "vue-i18n";
import axios from 'axios';

const general = useGeneralStore();
const {filtrosMapa, filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
const {} = general;

const config = useConfigStore();
const { coinSelected } = storeToRefs(config);

const users = useUsersStore()
const {  } = storeToRefs(users)
const { url } = users

const {locale} = useI18n({ useScope: 'global' })
const lang = ref()
const X = ref('')

const api_products = '/api/public/products/home'

let priceRange = inject('priceRange')


const products_list = ref([])

const onSwiper = (swiper) => {
    console.log(swiper, 'swiper')
    swiper.pagination.render()
}

const fetchProducts = async () => {
    try {
        products_list.value = []
        const response = await axios.get(`${url}${api_products}?page=1`)
        console.log(response)
        products_list.value = response.data.data.data
        console.log(products_list.value)
    } catch (error) {
        console.log(error)
    }
}
fetchProducts()

</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 5px;
}
swiper-slide {
  height: auto !important;
}
@media (max-width: 991px){
    .mySwiper3 swiper-slide{
        padding-bottom: 10px;
    }
    .mySwiper{
        margin: 0 0 20px 0;
    }
}

.skeleton-card {
    min-width: 100%;
    min-height: 465px;
    max-height: 70vh;
    padding: 16px;
    border-radius: 8px;
    background-color: white;
}

.skeleton-header {
    height: 240px;
    background-color: #e0e0e0;
    border-radius: 8px;
    margin-bottom: 16px;
}

.skeleton-image {
    width: 100%;
    height: 100%;
    background-color: #ccc;
    border-radius: 8px;
    animation: skeleton-loading 1.5s infinite ease-in-out;
}

.skeleton-body {
    padding: 8px 0;
}

.skeleton-title, .skeleton-subtitle, .skeleton-icon, .skeleton-price, .skeleton-button {
    animation: skeleton-loading 1.5s infinite ease-in-out;
}

.skeleton-title {
    width: 70%;
    height: 20px;
    background-color: #e0e0e0;
    margin-bottom: 10px;
    border-radius: 4px;
}

.skeleton-subtitle {
    width: 50%;
    height: 20px;
    background-color: #e0e0e0;
    margin-bottom: 10px;
    border-radius: 4px;
}

.skeleton-meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.skeleton-icon {
    width: 30px;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 50%;
}

.skeleton-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.skeleton-price {
    width: 50px;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 4px;
}

.skeleton-button {
    width: 80px;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 8px;
}

@keyframes skeleton-loading {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e0e0e0;
    }
}

.slide{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    width: 100%;
    min-height: 465px;
    max-height: 70vh;
    .ejemplo{
        width: 100%;
        border-radius: 15px;
        object-fit: cover;
        height: 240px;
    }
    .caption{
        position: relative;
        margin: 10px 0;
        padding: 0 5px;
        .position{
            position: absolute;
            top: 0;
            min-height: 100%;
            h3{
                font-weight: 700;
                font-size: 16px;
                color: var(--bgPrincipal);
            }
            h4{
                font-weight: 400;
                font-size: 15px;
                color: var(--bgPrincipal);
            }
            h5{
                font-weight: 700;
                font-size: 15px;
                color: var(--bgPrincipal);
                padding-left: 5px;
            }
            h6{
                font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: #FEB230;
            }
            p{  font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: var(--bgPrincipal);
                padding-left: 3px;
            }
            .row__slide{
                position: relative;
                min-height: 100% !important;
                min-height: 250px;
                border-radius: 15px;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 80%);
                .arriba{
                    position: absolute;
                    top: 3%;
                }
                .abajo{
                    position: absolute;
                    bottom: 3%;
                }
            }
            
        }
    }
    .descripcion{
        
        h2{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.2;
            text-align: left;
        }
        h3{
            color: #B8C1DB;
            font-weight: 500;
            font-size: 15px;
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: start;
            img{
                margin-left: 5px;
            }
        }
        p{
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 15px;
        }
        .colaboradores{
            position: relative;
            max-width: 45%;
            .img_ejemplo{
                position: absolute;
                left: 2%;
                object-fit: cover;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                margin-right: 5px;
            }
            .colaboran{
                background-color: #697891;
                box-shadow: 0px 3px 6px #00000029;
                border: 2px solid #FFFFFF;
                border-radius: 50%;
                width: 42px;
                height: 42px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 18%;
            }
        }
        .creador{
            border-right: 1px solid rgba(184, 193, 219, 0.3);
        }
        .border-bottom{
            width: 90%;
            height: 1px;
            background-color: rgba(184, 193, 219, 0.3);
        }
    }
    .horarios{
        h6{
            color: #7D93B2;
            font-weight: 500;
            font-size: 14px;
            padding-left: 5px;
        }
        .border2{
            width: 1px;
            height: 100%;
            background-color: rgba(184, 193, 219, 0.3);
            margin: 0 10px;
        }
        h3{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 15px;
        }
        .col-perso2{
            max-width: 25%;
        }
    }
    .comprar{
        margin-bottom: 5px;
        margin-top: 15px;
        h2{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
        }
        h5{
            font-size: 14px;
            font-weight: 400;
            color: #B8C1DB;
            text-decoration: line-through;
        }
        .padding{
            padding-left: 20px;
        }
    }
    // padding: 5px 5px !important;
}

</style>