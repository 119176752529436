<template>
    <div class="row unete" v-show="show">
        <div class="col-12 d-flex justify-content-between align-items-center position-relative">
            <h5>{{$t('message.joinMasterclass')}}</h5>
            <BTNPrimario :textoBTNPrimario="$t('message.bePremium')" :sinBack=true :route="'https://www.expophotomasterclass.com/membresia'"/>
            <button class="close-btn" @click="show = false">×</button>
        </div>
    </div>
</template>

<script setup>
import BTNPrimario from "../Botones/Primario.vue";
import { useGeneralStore } from "../../stores/general"
import { storeToRefs } from "pinia";
import { useRouter } from 'vue-router';
import { ref } from 'vue';

const router = useRouter()

const show = ref(true)
console.log(show.value)
const general = useGeneralStore();
const {textos} = storeToRefs(general);
const {} = general;
</script>

<style lang="scss" scoped>
.unete {
    width: 100%;
    background-color: #F5F5F5;
    box-shadow: 0px 3px 6px #00000029;
    min-height: 50px;
    position: fixed;
    bottom: 95px;
    padding: 10px;
    z-index: 99;
    h5 {
        font-weight: 700;
        font-size: 18px;
        color: var(--colorSecondary);
        min-width: 60%;
    }
    .close-btn {
        background-color: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
        height: 25px;
        min-width: 25px;
        border-radius: 50%;
        font-size: 20px;
        color: #000;
        cursor: pointer;
        padding: 0;
        margin-left: 15px;
        z-index: 3;
        position: absolute;
        top: -5px;
        right: 95%;

    }
    @media (min-width: 992px) {
        width: 33vw;
        right: 0;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        transition: all 0.2s;
        .close-button{
            top: -25px;
            right: 100%;
        }
    }
}

</style>