import { createRouter, createWebHistory } from 'vue-router'
/*---HOME-----*/
import HomeView from '../views/Home/Index.vue'
import HomeCursosResultados from '../views/Home/Resultados.vue'
import HomeCursos from '../views/Home/Cursos.vue'
import Preset from '../views/Home/Preset.vue'
/*---OTROS sin categorizar-----*/
import Asesorias from '../views/Resultados/Asesorias.vue'
import ReservarAsesor from '../views/ReservarAsesor.vue'
/*---CARRITO-----*/
import Carrito from '../views/Carrito/Index.vue'
/*---INICIO-----*/
import IngresarCodigo from '../views/Ingresar/Codigo.vue'
import IngresarFacebbok from '../views/Ingresar/Facebook.vue'
import Iniciar from '../views/Ingresar/Index.vue'
import Recuperar from '../views/Ingresar/Recuperar.vue'
import Reestablecer from '../views/Ingresar/Reestablecer.vue'
import RegistraseTelefono from '../views/Ingresar/Telefono.vue'
import RegistrarseUsuario from '../views/Ingresar/Usuario.vue'
import Registrarse from '../views/Ingresar/Registrarse.vue'
import Roles from '../views/Ingresar/Roles.vue'
import RegistrarseTerminar from '../views/Ingresar/Terminar.vue'
/*-----RESULTADOS-----*/
import ResultadosCursos from '../views/Resultados/Cursos.vue'
import ResultadosMarcas from '../views/Resultados/Marcas.vue'
import ResultadosPresets from '../views/Resultados/Presets.vue'
import ResultadosProfesores from '../views/Resultados/Profesores.vue'
import ResultadosGenerales from '../views/Resultados/Generales.vue'
/*---OTROS-----*/
import Splash from '../views/Otros/Splash.vue'
import Pedido from '../views/Otros/PedidoEstado.vue'
// import PedidoPendiente from '../views/Otros/PedidoPendiente.vue'
// import PedidoFallido from '../views/Otros/PedidoFallido.vue'
import PageNotFound from '../views/Otros/ErrorPage.vue'
import SinResultados from '../views/Otros/SinResultados.vue'
/*---MC PLUS-----*/
import PagoPlus from '../views/MCPlus/Pago.vue'
import PlanesPlus from '../views/MCPlus/Planes.vue'
/*---PANEL---*/
/*---Productos---*/
import MisProductosAdquiridos from '../views/Panel/Productos/Index.vue'
import MisProductos from '../views/Panel/Productos/MisProductos.vue'
import VerProducto from '../views/Panel/Productos/VerProducto.vue'
/*---Certificados---*/
import Certificados from '../views/Panel/Certificados/Index.vue'
/*---Cuenta---*/
import Ajustes from '../views/Panel/Cuenta/Ajustes.vue'
import Cuenta from '../views/Panel/Cuenta/Index.vue'
/*---MARCA-----*/
import PerfilMarca from '../views/Marca/Perfil.vue'
/*--- USUARIO-----*/
import PerfilUsuario from '../views/Usuario/Perfil.vue'
/*--- VIENDO CURSO-----*/
import ViendoCurso from '../views/ViendoCurso/Index.vue'
/*--- SUBIR PRODUCTOS-----*/
import SubirProductos from '../views/Panel/SubirProductos/Index.vue'
import SubirCurso from '../views/Panel/SubirProductos/Cursos.vue'
import SubirPreset from '../views/Panel/SubirProductos/Presets.vue'
import Editar from '../views/Panel/SubirProductos/Editar.vue'
/*--- AFILIADOS-----*/
import Afiliados from '../views/Panel/Afiliados/Index.vue'
/*--- PANTALLAS ADMIN -----*/
/*--- Dashboard ---*/ 
import Dashboard from '../views/Admin/Dashboard/Index.vue'
/*--- Referidos -----*/
import Referidos from '../views/Admin/Referidos/Index.vue'
/*--- Usuarios -----*/
import Usuarios from '../views/Admin/Usuarios/Index.vue'
import Usuario from '../views/Admin/Usuarios/Usuario.vue'
/*--- Ajustes -----*/
import AjustesAdmin from '../views/Admin/Ajustes/Index.vue'
/*--- Concursos -----*/
import Concursos from '../views/Admin/Concursos/Index.vue'
/*--- Productos -----*/
import Productos from '../views/Admin/Productos/Index.vue'
import VerMiProductos from '../views/Admin/Productos/VerProducto.vue'
/*--- Estadisticas -----*/
import EstadisticasFinancieras from '../views/Admin/Estadisticas/Financieras/Index.vue'
import EstadisticasAcademicas from '../views/Admin/Estadisticas/Academicas/Index.vue'
import EstadisticasAcademicasUsuario from '../views/Admin/Estadisticas/Academicas/Usuario.vue'

import HomeM from '../views/Admin/Usuarios/Home.vue'
import Terms from '../views/Otros/Terms.vue'
import Privacy from '../views/Otros/Privacy.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/asesorias',
    name: 'asesorias',
    component: Asesorias
  },
  {
    path: '/carrito/:id',
    name: 'carrito',
    component: Carrito
  },
  {
    path: '/home-cursos-resultados',
    name: 'HomeCursosResultados',
    component: HomeCursosResultados
  },
  {
    path: '/home-cursos/:slug?/:id?',
    name: 'HomeCursos',
    component: HomeCursos
  },
  {
    path: '/ingresar-codigo',
    name: 'IngresarCodigo',
    component: IngresarCodigo
  },
  {
    path: '/ingresar-facebook',
    name: 'IngresarFacebbok',
    component: IngresarFacebbok
  },
  {
    path: '/iniciar/:route?',
    name: 'Iniciar',
    component: Iniciar
  },
  {
    path: '/recuperar',
    name: 'Recuperar',
    component: Recuperar
  },
  {
    path: '/registrarse-telefono',
    name: 'RegistraseTelefono',
    component: RegistraseTelefono
  },
  {
    path: '/registrarse-usuario',
    name: 'RegistrarseUsuario',
    component: RegistrarseUsuario
  },
  {
    path: '/registrarse',
    name: 'Registrarse',
    component: Registrarse
  },
  {
    path: '/registro-terminar',
    name: 'RegistrarseTerminar',
    component: RegistrarseTerminar
  },
  {
    path: '/reservar-asesor/:id',
    name: 'ReservarAsesor',
    component: ReservarAsesor
  },
  {
    path: '/resultados-cursos',
    name: 'ResultadosCursos',
    component: ResultadosCursos
  },
  {
    path: '/resultados-marcas',
    name: 'ResultadosMarcas',
    component: ResultadosMarcas
  },
  {
    path: '/resultados-presets',
    name: 'ResultadosPresets',
    component: ResultadosPresets
  },
  {
    path: '/resultados-profesores/:search?',
    name: 'ResultadosProfesores',
    component: ResultadosProfesores
  },
  {
    path: '/roles/:route?',
    name: 'Roles',
    component: Roles
  },
  {
    path: '/splash',
    name: 'Splash',
    component: Splash
  },
  /*---  PÁGINAS PANEL ----*/
  {
    path: '/page-not-found',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/sin-resultados',
    name: 'SinResultados',
    component: SinResultados
  },
  {
    path: '/pagos-plus',
    name: 'PagoPlus',
    component: PagoPlus
  },
  {
    path: '/planes-plus',
    name: 'PlanesPlus',
    component: PlanesPlus
  },
  {
    path: '/certificados',
    name: 'Certificados',
    component: Certificados
  },
  {
    path: '/pedido',
    name: 'Pedido',
    component: Pedido
  },
  // {
  //   path: '/pedido-pendiente',
  //   name: 'PedidoPendiente',
  //   component: PedidoPendiente
  // },
  // {
  //   path: '/pedido-fallido',
  //   name: 'PedidoFallido',
  //   component: PedidoFallido
  // },
  {
    path: '/mis-productos',
    name: 'MisProductosAdquiridos',
    component: MisProductosAdquiridos
  },
  {
    path: '/productos-configuracion',
    name: 'MisProductos',
    component: MisProductos
  },
  {
    path: '/ajustes',
    name: 'Ajustes',
    component: Ajustes
  },
  {
    path: '/cuenta',
    name: 'Cuenta',
    component: Cuenta
  },
  {
    path: '/perfil-marca/:id',
    name: 'PerfilMarca',
    component: PerfilMarca
  },
  {
    path: '/perfil-usuario/:slug',
    name: 'PerfilUsuario',
    component: PerfilUsuario
  },
  {
    path: '/viendo-curso/:slug?/:id?',
    name: 'ViendoCurso',
    component: ViendoCurso
  },
  {
    path: '/preset/:id',
    name: 'Preset',
    component: Preset
  },
  {
    path: '/resultados-generales',
    name: 'ResultadosGenerales',
    component: ResultadosGenerales
  },
  {
    path: '/reestablecer',
    name: 'Reestablecer',
    component: Reestablecer
  },
  {
    path: '/subir-producto',
    name: 'SubirProductos',
    component: SubirProductos
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/afiliados',
    name: 'Afiliados',
    component: Afiliados
  },
  {
    path: '/subir-curso/:type',
    name: 'SubirCurso',
    component: SubirCurso
  },
  {
    path: '/editar-curso/:type/:id',
    name: 'Editar',
    component: Editar
  },
  {
    path: '/subir-preset',
    name: 'SubirPreset',
    component: SubirPreset
  },
  {
    path: '/referidos',
    name: 'Referidos',
    component: Referidos
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: Usuarios
  },
  {
    path: '/nuevo-usuario',
    name: 'Nuevo Usuario',
    component: Usuario
  },
  {
    path: '/editar-usuario/:id',
    name: 'Editar Usuario',
    component: Usuario
  },
  {
    path: '/ajustes-admin',
    name: 'AjustesAdmin',
    component: AjustesAdmin
  },
  {
    path: '/concursos',
    name: 'Concursos',
    component: Concursos
  },
  {
    path: '/productos',
    name: 'Productos',
    component: Productos
  },
  {
    path: '/ver-mi-producto/:id',
    name: 'VerMiProductos',
    component: VerMiProductos
  },
  {
    path: '/ver-producto/:id',
    name: 'VerProducto',
    component: VerProducto
  },
  {
    path: '/estadisticas-financieras/:id',
    name: 'EstadisticasFinancieras',
    component: EstadisticasFinancieras
  },
  {
    path: '/estadisticas-academicas/:id',
    name: 'EstadisticasAcademicas',
    component: EstadisticasAcademicas
  },
  {
    path: '/estadisticas-academicas-usuario/:id',
    name: 'EstadisticasAcademicasUsuario',
    component: EstadisticasAcademicasUsuario
  },
  {
    path: '/maqueta/:param?',
    name: 'Maqueta',
    component: HomeM
  },
  {
    path: '/terminos',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/privacidad',
    name: 'Privacy',
    component: Privacy
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
