<template>
    <div class="tab-pane fade my-3 row justify-content-center active show" id="home" role="tabpanel" aria-labelledby="home-tab">
        <TituloHomeCursos :tituloCurso="$t('message.courseTitle')" class="d-lg-none"/>
        <profesorHomeCursos class="d-lg-none"/>
        <rateProfesorCurso class="d-lg-none"/>
        <!-- DESCRIPCION -->
        <TituloHomeCursos :tituloCurso="$t('message.courseDescription')" :tituloMediano="true" id="0"/>
        <DescripcionCursoTexto/>
        <!-- QUE INCLUYE -->
        <TituloHomeCursos :tituloCurso="$t('message.whatIncludesCourse')" :tituloMediano="true" v-if="!containsHTML()"/>
        <QueIncluyeCursos v-if="!containsHTML()"/>
        <!-- FECHA -->
        <!-- <TituloHomeCursos :tituloCurso="$t('message.datesCourses')" :tituloMediano="true" id="1"/>
        <fechasHomeCursos/> -->
        <!-- CONTENIDO -->
        <TituloHomeCursos :tituloCurso="$t('message.contentCourse')" :tituloMediano="true" id="2" v-if="product.units.length > 0"/>
        <contenidoHomeCursos />
        <ModulosHomeCursos />
        <!-- CONTACTO -->
        <!-- <ContactarHomeCursos class="d-none d-lg-flex"/> -->
        <!-- PROFESIONAL -->
        <!-- <TituloHomeCursos :tituloCurso="$t('message.teacherCourse')" :tituloMediano="true" class="d-lg-none" id=""/> -->
        <TituloHomeCursos :tituloCurso="$t('message.teacherCourse')" :tituloMediano="true" class="d-none d-lg-flex" id="3"/>
        <!-- <InfoProfesorHomeCursos class="d-lg-none"/> -->
        <!-- <PerfilProfesorHomeCursos class="d-none d-lg-none"/> -->
        <InfoProfesorHomeCursosV3 class="d-none d-lg-flex"/>
        <!-- BENEFICIOS -->
        <TituloHomeCursos :tituloCurso="$t('message.benefitsCourse')" :tituloMediano="true" id="4"  v-if="product.benefits"/>
        <BeneficiosHomeCursos v-if="product.benefits"/>
        <!-- PORTFOLIO ESTUDIANTES -->
        <!-- <TituloHomeCursos :tituloCurso="$t('message.studentPortfolio')" :tituloMediano="true" class="d-none"/>
        <sliderPortafolioEstudiantesHomeCursos class="d-none"/> -->
        <!-- RATING -->
        <!-- <TituloHomeCursos :tituloCurso="$t('message.ratings')" :tituloMediano="true"/>
        <valoracionesHomeCursos/> -->
        <!-- OPINIONES -->
        <!-- <TituloHomeCursos :tituloCurso="$t('message.opinionsCourse')"/>
        <opinionHomeCursos/> -->
        <!-- FAQS -->
        <TituloHomeCursos :tituloCurso="$t('message.FAQ')" v-if="product.faq"/>
        <PreguntasFrecuentesHomeCursos/>
    </div>
</template>

<script setup>
    import BeneficiosHomeCursos from "./Beneficios.vue";
    import contenidoHomeCursos from "./Contenido.vue";
    import DescripcionCursoTexto from "./DescripcionCursoTexto.vue";
    import InfoProfesorHomeCursos from "./InfoProfesor.vue";
    import InfoProfesorHomeCursosV3 from "./InfoProfesorV3.vue";
    import ModulosHomeCursos from "./Modulos.vue";
    import PerfilProfesorHomeCursos from "./PerfilProfesor.vue";
    import PreguntasFrecuentesHomeCursos from "./PreguntasFrecuentes.vue";
    import profesorHomeCursos from "./Profesor.vue";
    import QueIncluyeCursos from "./QueIncluye.vue";
    import rateProfesorCurso from "./RateProfesorCurso.vue";
    import TituloHomeCursos from "../Titulo/TituloHomeCursos.vue";

    import BTNMostrarMas from "../Botones/MostrarMas.vue";
    import { ref, inject } from 'vue';

    const product = inject('product')

    const description = ref(product.value.description)

    const containsHTML = (description) => {
        const div = document.createElement('div');
        div.innerHTML = description;
        return div.innerHTML !== description;  
    }

    const seeDescription = () =>{
        if (containsHTML(product.description)) {
            
        } else {
            console.log("La descripción no contiene HTML.");
        }
    }
    seeDescription()

</script>


<style lang="scss" scoped>

</style>