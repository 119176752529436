<template>
    <div class="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-center opinion px-0">
        <OpinionYRespuesta/>
        <div class="row justify-content-center row__width pb-3">
            <OpinionIndividual/>
            <!-- <ResponderOpinion/> -->
            <OpinionIndividual v-for="(opinion, id) in opiniones" v-if="desaparecer"/>
        </div>
        <div class="row justify-content-center row__width d-lg-none">
            <div class="col-12 d-flex flex-column justify-content-start align-items-center py-1 px-0">
                <div class="before" id="filtro5">
                    <p>
                        Es impresionante la pasión con la que cuenta Mónica Olvera y el conocimiento que tiene para explicar y resolver las dudas durante el curso, 
                        aprendí demasiado. Lo recomiendo completamente, ideal para comenzar a 
                        prepararte profesionalmente en el mundo de la fotografía de newborn por profesionales reconocidos.
                    </p>
                    <div id="desaparecer5" class="d-none">
                        <p>
                            Lo recomiendo completamente, ideal para comenzar a 
                            prepararte profesionalmente en el mundo de la 
                            fotografía de newborn por profesionales reconocidos.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center row__width d-lg-none">
            <div class="col-12 d-flex justify-content-start align-items-center botones px-0">
                <h6>¿Te gusto este comentario?</h6>
                <button><img src="../../img/home-icons/cursos-icons/like.svg" alt=""></button>
                <button><img src="../../img/home-icons/cursos-icons/dislike.svg" alt=""></button>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                <BTNMostrarMas :funcionTexto="mostrarTexto5" disabled/>
            </div>
        </div>
        <div class="row justify-content-center row__width d-none d-lg-flex">
            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                <BTNMostrarMas :funcionTexto="mostrarOpiniones" disabled/>
            </div>
        </div>
    </div>
</template>

<script setup>
import BTNMostrarMas from "../Botones/MostrarMas.vue";
import OpinionIndividual from "../Cursos/OpinionIndividual.vue";
import ResponderOpinion from "../Perfil/ResponderOpinion.vue";
import OpinionYRespuesta from "../Cursos/OpinionYRespuesta.vue";
import { ref, onMounted, inject } from 'vue'
import axios from 'axios'
import { useUsersStore } from "../../stores/user";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from 'vue-router';

const users = useUsersStore();
const {cambioHeader} = storeToRefs(users);
const { url } = users

const router = useRouter();
const route = useRoute()

const opiniones = ref([
    {mostrar: false},
    {mostrar: false},
    {mostrar: false},
    {mostrar: false},
    {mostrar: false},
    {mostrar: false},
])

const professional = inject('professional')

const desaparecer = ref(false)

const mostrarTexto5 = () => {
    document.getElementById('desaparecer5').classList.toggle('d-none')
    document.getElementById('filtro5').classList.toggle('before')
}

const mostrarOpiniones = () => {
    if(desaparecer == false){
        desaparecer = true
    }else{
        desaparecer = false
    }
}


const fetchStatistics = async () => {
    const body = {
        reviewable_type: "PROFESSIONAL",
        reviewable_slug: professional.value.slug
    }

    try {
        const response = await axios.post(url+'/api/public/professionals/profile/reviews/index', body)

        if (response.data.status === 'Success') {
            console.log(response)
            // const { total_score, stars } = response.data.data

            // rates.value = Array.from({ length: total_score }, () => ({}))

            // progresos.value = [
            //     { progreso: stars.five * 100 / response.data.data.quantity, titulo: `${stars.five}` },
            //     { progreso: stars.four * 100 / response.data.data.quantity, titulo: `${stars.four}` },
            //     { progreso: stars.three * 100 / response.data.data.quantity, titulo: `${stars.three}` },
            //     { progreso: stars.two * 100 / response.data.data.quantity, titulo: `${stars.two}` },
            //     { progreso: stars.one * 100 / response.data.data.quantity, titulo: `${stars.one}` }
            // ]
        }
    } catch (error) {
        console.error('Error fetching the reviews statistics:', error)
    }
}

// Llamar a fetchStatistics cuando el componente se monte
onMounted(() => {
    fetchStatistics()
})
</script>

<style lang="scss" scoped>
.opinion{ 
    h2{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
    }
    h4{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
        padding-left: 10px;
    }
    .starts{
        img{
            padding-right: 3px;
        }
    }
    p{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    .perfil-foto{
        max-width: max-content;
        img{
            height: 68px;
            border-radius: 50%;
            max-width: 68px;
        }
    }
    h6{
        font-weight: 400;
        font-size: 12px;
        color: #B8C1DB;
    }
    .botones{
        button{
            border: 0;
            background-color: transparent;
            outline: 0;
            &:focus{
                outline: 0;
            }
        }
    }
    .before_respuesta{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            background-color: #E4E4E4;
            border-radius: 50px;
            width: 2px;
            height: 110%;
            left: -5%;
        }
    }
    h5{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    .contenedor{
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        input{
            border: 2px solid #E9E3E3;
            background-color: #FCFCFC;
            border-radius: 10px;
            height: 50px;
            width: 100%;
            color: #697891;
            font-weight: 400;
            font-size: 15px;
            padding-left: 15px;
            &:hover, &:focus{
                outline: 0;
                border-color: var(--colorPrimary);
            }
        }
        img{
            position: absolute;
            margin-right: 20px;
        }

    }
    @media (min-width: 992px) {
        margin: 40px 0px;
        .perfil-foto{
           img{
                height: 70px;
                max-width: 78px;
           }
        }
        .flex-cambio{
            flex-direction: column;
        }
        .before{
            &::before{
                position: relative;
            }
        }
    }
}
</style>