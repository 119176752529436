<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center valoraciones">
        <div class="row justify-content-center row__width">
            <div class="col-12 col-lg-3 d-flex flex-column justify-content-center align-items-center">
                <h2>{{ total_score }}</h2>
                <div class="d-flex justify-content-center align-items-center pb-3">
                    <!-- Mostrar estrellas en función de los datos obtenidos -->
                    <template v-if="rates">
                        <img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="" v-for="(rate, id) in rates" :key="id">
                    </template>
                    <template v-else-if="!rates">
                        <img src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="" v-for="(rate, id) in noRate" :key="id">
                    </template>
                </div>
                <h3>{{$t('message.ratingCourse')}}</h3>
            </div>
            <div class="col-12 col-lg-9 d-flex flex-column justify-content-center align-items-center px-0">
                <div class="row justify-content-between align-items-center row__width" v-for="(progreso, id) in progresos" :key="id">
                    <div class="progress col-10 d-flex justify-content-start align-items-center px-0">
                        <div class="progress-bar" role="progressbar" :style="`width:` + progreso.progreso + `%`" style="height: 100%; border-radius: 50px;" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="col-2 d-flex justify-content-end align-items-center px-0">
                        <p>{{progreso.titulo}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import axios from 'axios'
import { useUsersStore } from "../../stores/user";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from 'vue-router';

const users = useUsersStore();
const {cambioHeader} = storeToRefs(users);
const { url } = users

const router = useRouter();
const route = useRoute()

// Definir las variables reactivas
const progresos = ref([
    {progreso: 0, titulo: '0%'},
    {progreso: 0, titulo: '0%'},
    {progreso: 0, titulo: '0%'},
    {progreso: 0, titulo: '0%'},
    {progreso: 0, titulo: '0%'}
])

const rates = ref(null)
const noRate = ref([{}, {}, {}, {}, {}])
const total_score = ref(0)
const professional = inject('professional')

const fetchStatistics = async () => {
    const body = {
        reviewable_type: "PROFESSIONAL",
        reviewable_slug: professional.value.slug
    }

    try {
        const result = await axios.post(url + '/api/public/professionals/profile/reviews/statistics', body);
        const { stars, quantity, total_score } = result.data.data;
        console.log(result)
        total_score.value = total_score
        
        console.log(total_score.value, total_score)

        const totalRatings = stars.five + stars.four + stars.three + stars.two + stars.one;
        const averageRating = totalRatings > 0 ? (
        (5 * stars.five + 4 * stars.four + 3 * stars.three + 2 * stars.two + stars.one) / totalRatings
        ) : 0;

        rates.value = totalRatings > 0 ? Array.from({ length: Math.round(averageRating) }, () => ({})) : null;

        if (quantity > 0) {
            progresos.value = [
                { progreso: stars.five * 100 / quantity, titulo: `${stars.five}` },
                { progreso: stars.four * 100 / quantity, titulo: `${stars.four}` },
                { progreso: stars.three * 100 / quantity, titulo: `${stars.three}` },
                { progreso: stars.two * 100 / quantity, titulo: `${stars.two}` },
                { progreso: stars.one * 100 / quantity, titulo: `${stars.one}` }
            ];
        } else {
            progresos.value = [
                { progreso: 0, titulo: '0' },
                { progreso: 0, titulo: '0' },
                { progreso: 0, titulo: '0' },
                { progreso: 0, titulo: '0' },
                { progreso: 0, titulo: '0' }
            ];
        }
    } catch (error) {
            console.error('Error fetching the reviews statistics:', error);
    }
}


// Llamar a fetchStatistics cuando el componente se monte
onMounted(() => {
    fetchStatistics()
})
</script>


<style lang="scss" scoped>
.valoraciones{
    h2{
        font-weight: 700;
        font-size: 50px;
        color: var(--colorSecondary);
        padding: 5px 0px;
    }
    h3{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
    }
    img{
        padding: 0 2px;
    }
    p{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
    }
    .progress{
        height: 10px;
    }
    @media (min-width: 992px) {
        margin: 20px 0 50px 0;
        h2{
            color: #374557;
        }
    }
}
</style>