<template>
    <!-- <div class="container-fluid">
        <NavbarBuscadorBack/>
        <div class="row justify-content-center position-relative body">
            <div class="col-4 d-none d-lg-flex justify-content-center align-items-start ps-0" v-if="mapaDesplegado"></div>
            <div class="col-3 justify-content-center align-items-start ps-0 mt-lg-5" v-if="filtrosDesplegados">
                <Body/>
            </div>
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 table-space"
            :class="filtrosDesplegados == true ? 'col-lg-8 justify-content-lg-start' : 'col-lg-11' && mapaDesplegado == true ? 'col-lg-7 justify-content-lg-start' : 'col-lg-11' ">
                <Filtros class=""/>
                <TitulosSecciones :titulo="$t('message.titleDirectory')" :haySubtitulo="false" class="d-lg-none"/>
                <OrdenarPorSelect class="d-lg-none"/>
                <TituloYOpcionesResultadosCursosV3 :titulo="$t('message.titleDirectoryProfessionals')" class="d-none d-lg-flex" idNext="nextSliderFotosboton" idBack="prevSliderFotosboton" pagination="pagProfesional"/>
                <directorioDeFotos/>
                <BotonesSlidersHome class="d-lg-none" idNext="nextSliderFotosboton" idBack="prevSliderFotosboton" pagination="pagProfesional"/>
                <panelSeccion class="d-lg-none"/>
            </div>
            <div class="col-4 d-none d-lg-flex justify-content-center align-items-start ps-0" v-if="mapaDesplegado"></div>
            <div class="col d-none d-lg-flex justify-content-center align-items-start ps-0 mt-lg-5 position" v-if="mapaDesplegado">
                <Mapa/>
            </div>
            <div class="filtros-absolute" v-if="filtrosMapa" @click="cerrarTodo">
                
            </div>
            <div class="filtros row d-flex justify-content-center align-items-start table-space" v-if="filtrosMapa">
                <div class="col-11 d-flex justify-content-center align-items-center px-0">
                    <FiltrosV2 :funcionMostrarFiltros="funcionMostrarFiltros" :funcionMapa="funcionMapa2"/>
                </div> 
                <div class="col-3 d-flex justify-content-center align-items-start ps-0">
                    <Body/>
                </div>
                <div class="col-8 d-flex flex-column justify-content-center align-items-center px-0 position-relative">
                    <TituloYOpcionesResultadosCursosV3 :titulo="$t('message.titleDirectoryProfessionals')" class="d-none d-lg-flex" idNext="nextSliderFotosboton" idBack="prevSliderFotosboton" pagination="pagProfesional"/>
                    <directorioDeFotos/>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 mt-5">
               <panelSeccionV2 class="d-none d-lg-flex"/>
                <TitulosSeccionesV2 :titulo="$t('message.topTeachers')" :haySubtitulo="false" class="d-lg-none" />
                <TituloYOpcionesResultadosCursosV2 :titulo="$t('message.topTeachers')" class="d-none d-lg-flex" idNext="nextSliderProfesoresboton" idBack="prevSliderProfesoresboton" />
            </div>
            <div class="col-11 col-lg-12 d-flex flex-column justify-content-center align-items-center px-0">        
                <TopProfesores/>
            </div>
        </div>
        <FooterHome/>
        <Footer :activeHome="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div> -->
    <div class="container-fluid">
        <NavbarBuscadorBack/>
        <HeaderTitulo class="d-none d-lg-flex" :tituloHeader="$t('message.HomeProfessionals')"/>
        <div class="row justify-content-center position-relative">
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 px-lg-auto position-relative">
                <TitulosSecciones :titulo="$t('message.titleDirectoryProfessionals')" :haySubtitulo="false"/>
                <!-- <Filtros class=""/> -->
                <div class="row justify-content-center align-items-center my-4 row__width table-space">
                    <DirectorioDeFotosV3Snippet/>
                </div>

                <!-- Paginación -->
                <template v-if="professional_list">
                    <nav v-if="professional_list.links" class="py-2">
                        <ul class="pagination">
                        <!-- Previous link -->
                            <!-- <li v-if="professional_list.links[0]" :class="{'disabled': !professional_list.prev_page_url}" class="page-item buttons">
                                <a class="page-link" href="#" v-html="professional_list.links[0].label" @click.prevent="fetchUsers(professional_list.prev_page_url)" :disabled="!professional_list.prev_page_url"></a>
                            </li> -->

                            <!-- Display first 5 page links after Previous -->
                            <li v-for="(link, index) in professional_list.links" :key="link.label" :class="{'active': link.active}" class="page-item">
                                <a v-if="link.url" class="page-link" href="#" v-html="link.label" @click.prevent="fetchProfessionals(link.url)"></a>
                                <span v-else class="page-link" v-html="link.label"></span>
                            </li>

                        <!-- Next link -->
                            <!-- <li v-if="professional_list.links[professional_list.links.length - 1]" :class="{'disabled': !professional_list.next_page_url}" class="page-item buttons">
                                <a class="page-link" href="#" v-html="professional_list.links[professional_list.links.length - 1].label" @click.prevent="fetchUsers(professional_list.next_page_url)" :disabled="!professional_list.next_page_url"></a>
                            </li> -->
                        </ul>
                    </nav>
                </template>

                <!-- <BotonesSlidersHome class="d-lg-none" idNext="nextSliderCursosboton" idBack="prevSliderCursosboton"/>
                <TitulosSecciones :titulo="$t('message.coursesDiscount')" :haySubtitulo="false" class="d-none"/> -->
                <!-- <OrdenarPorSelect class="d-lg-none"/> -->
                <!-- <TituloYOpcionesResultadosCursos class="d-none d-lg-flex mt-lg-4" :titulo="$t('message.coursesDiscount')" :lista="2" :categoria="2" idNextV2="nextSliderCursosV2boton" idBackV2="prevSliderCursosV2boton"/>
                <SliderMobileSeccionesResultadosCursosV2/>
                <BotonesSlidersHome class="d-lg-none" idNext="nextSliderCursosV2boton" idBack="prevSliderCursosV2boton"/> -->
                <!-- <panelSeccion class="d-lg-none"/> -->
                <!-- <PanelSeccionV3 :class="filtrosDesplegados == true ? 'd-none' : 'd-flex' || mapaDesplegado == true ? 'd-none' : 'd-flex'"/> -->
                <!-- <TitulosSeccionesV2 :titulo="$t('message.exploreCategories')" :class=" filtrosDesplegados == true ? 'd-none' : 'd-flex' || mapaDesplegado == true ? 'd-none' : 'd-flex' "/> -->
                <!-- <CategoriasListado :class=" filtrosDesplegados == true ? 'd-none' : 'd-flex' || mapaDesplegado == true ? 'd-none' : 'd-flex' "/> -->
            </div>
            
            <div class="col-4 d-none d-lg-flex justify-content-center align-items-start ps-0" v-if="mapaDesplegado"></div>
            <div class="col d-none d-lg-flex justify-content-center align-items-start ps-0 mt-lg-5 position" v-if="mapaDesplegado">
                <MapaFiltros/>
            </div>
            <div class="filtros-absolute" v-if="filtrosMapa" @click="cerrarTodo">
                
            </div>
            <div class="filtros row d-flex justify-content-center align-items-start" v-if="filtrosMapa">
                <div class="col-11 d-flex justify-content-center align-items-center px-0">
                    <FiltrosV2 :funcionMostrarFiltros="funcionMostrarFiltros" :funcionMapa="funcionMapa2"/>
                </div>
                <div class="col-3 d-flex justify-content-center align-items-start ps-0">
                    <BodyFiltros/>
                </div>
                <div class="col-8 d-flex flex-column justify-content-center align-items-center px-0 position-relative">
                    <TituloYOpcionesResultadosCursos :titulo="$t('message.coursesPhotography')" idNextV2="nextSliderCursosboton" idBackV2="prevSliderCursosboton"/>
                    <SliderMobileSeccionesHomeV3 class=""/>
                    <!-- <TituloYOpcionesResultadosCursos class="mt-lg-4" :titulo="$t('message.coursesDiscount')" :lista="2" :categoria="2" idNextV2="nextSliderCursosV2boton" idBackV2="prevSliderCursosV2boton"/>
                    <SliderMobileSeccionesResultadosCursosV3 class=""/> -->
                </div>
            </div>
        </div>
        <!-- <div class="row justify-content-center mt-lg-5" :class=" filtrosDesplegados == true ? 'd-flex' : 'd-none' || mapaDesplegado == true ? 'd-flex' : 'd-none' ">
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 px-lg-auto " >
                <PanelSeccionV3/>
                <TitulosSeccionesV2 :titulo="$t('message.exploreCategories')"/>
                <CategoriasListado/>
            </div>
        </div> -->
        <div class="espacio2"></div>
        <FooterHome />
        <Footer :activeHome="true" class="d-lg-none"/>
        <div class="espacio2"></div>

    </div>
</template>

<script setup>
import Footer from "../../components/Footer/Footer.vue";
import TitulosSecciones from "../../components/Titulo/TitulosSecciones.vue";
import panelSeccion from "../../components/PanelSeccion/PanelSeccion.vue";
import FooterHome from "../../components/Footer/FooterHome.vue";
import directorioDeFotos from "../../components/Sliders/DirectorioDeFotos.vue";
import OrdenarPorSelect from "../../components/Ordenar/PorSelect.vue";
import Filtros from "../../components/Filtros/Filtros.vue";
import TitulosSeccionesV2 from "../../components/Titulo/TitulosSeccionesV2.vue";
import BotonesSlidersHome from "../../components/Botones/SlidersHome.vue";
import TopProfesores from "../../components/Sliders/TopProfesores.vue";
import NavbarBuscadorBack from "../../components/Navs/NavbarBuscadorBack.vue";
import TituloYOpcionesResultadosCursosV2 from "../../components/Titulo/TituloYOpcionesV2.vue";
import TituloYOpcionesResultadosCursosV3 from "../../components/Titulo/TituloYOpcionesV3.vue";
import panelSeccionV2 from "../../components/PanelSeccion/PanelSeccionV2.vue";
import Body from "../../components/Filtros/Body.vue";
import FiltrosV2 from "../../components/Filtros/FiltrosV2.vue";
import Mapa from "../../components/Filtros/Mapa.vue";
import DirectorioDeFotosV3Snippet from "@/components/Sliders/DirectorioDeFotosV3Snippet.vue";
import HeaderTitulo from "@/components/Titulo/HeaderTitulo.vue";

import { ref, provide } from "vue";
import axios from "axios";
import { useGeneralStore } from "../../stores/general"    
import { useUsersStore } from "../../stores/user"
import { storeToRefs } from "pinia";

const users = useUsersStore()
const {  } = storeToRefs(users)
const { url } = users



const general = useGeneralStore();
const {filtrosDesplegados, filtrosMapa, mapaDesplegado} = storeToRefs(general);
const {} = general;

const funcionMostrarFiltros = () => {
    if(mapaDesplegado.value == true ){
        if(filtrosMapa.value == false){
            filtrosMapa.value = true 
        }else{
            filtrosMapa.value = false
        }
        //console.log(this.filtrosMapa)
    }else{
        if(filtrosDesplegados.value == false){
            filtrosDesplegados.value = true 
        }else{
            filtrosDesplegados.value = false
        }
    }
}

const funcionMapa = () => {
    if(filtrosDesplegados.value == true ){
        filtrosDesplegados.value = false
        //console.log('hola3')
    }
    else if(mapaDesplegado.value == false){
        mapaDesplegado.value = true 
        //console.log('hola4')
    }else{
        mapaDesplegado.value = false
        //console.log(mapaDesplegado.value)
        //console.log('hola5')
    }
}

const funcionMapa2 = () => {
    if(mapaDesplegado.value == true){
        //console.log(this.mapaDesplegado, 'hola1')
        mapaDesplegado.value = false 
        filtrosMapa.value = false
    }else{
        mapaDesplegado.value = false
        //console.log(this.mapaDesplegado, 'hola1')
    }
}

const cerrarTodo = () => {
    mapaDesplegado = false
    filtrosMapa.value = false
}

const api = '/api/public/professionals'

const professional_list = ref([])
const currentPage = ref(1)
const lastPage = ref(1)
const nextPageUrl = ref(null)
const prevPageUrl = ref(null)

provide("professional_list", professional_list)

const fetchProfessionals = async (page) => {
    try {
        professional_list.value = []
        const result = await axios.get(`${url}${api}?page=${page}`)
        console.log(result, 'result')
        professional_list.value = result.data.data
    } catch (error) {
        console.log(error)
    }
}

fetchProfessionals(1)
</script>

<style lang="scss" scoped>
.body{
    @media (min-width:992px) {
        margin-top: 95px;
    }
}
.position{
    position: absolute;
    right: 0;
    max-width: 34%;
    height: 100%;
    top: 0;
}
.filtros-absolute{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.35);
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    z-index: 5;
    height: 105%;
    min-height: 80vw;
}
.filtros{
    position: absolute;
    background-color: #FFFFFF;
    max-width: 95vw;
    min-height:  102.5%;
    z-index: 10;
    left: 0;
    top: 0;
    
    min-height: 80vw;
}

.table-space {
  display: grid;
  grid-template-columns: repeat(4, 1fr); // 4 elementos por fila en pantallas grandes
  gap: 30px; // Espacio entre elementos

  @media (max-width: 1440px) { 
    grid-template-columns: repeat(3, 1fr); // 3 elementos por fila en pantallas 'lg'
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr); // 2 elementos por fila en pantallas 'md'
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; // 1 elemento por fila en pantallas móviles
  }
}

.pagination{
    .page-item{
        border: 0;
        border-radius: 25px;
        margin: 0 2px;
        .page-link{
            border-radius: 25px;
            border: 0;
            font-weight: 500;
            color: var(--colorSecondary);
            font-family: 'Poppins';
            a{
                &:hover{
                    background-color: var(--colorPrimary);
                    color: var(--colorSecondary);
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    .buttons{
        border-radius: 15px;
        .page-link{
            border-radius: 15px;
        }
    }
    
    .active{
        background-color: var(--colorPrimary);
        .page-link{
            color: #fff !important;
        }
        a{
            color: #fff !important;
        }
    }

}
</style>